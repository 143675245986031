<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos.superadmin" class="main-container">       
        <div v-if="loaded" class="articulo-principal">
          
          <table>
            <thead>
              <tr>
                <th style="width:80px"></th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Empresa</th>
                <th>Venta</th>
                <th>A pagar</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in profesionales" :key="p.profesional.id">
                <td>
                  <img 
                    :src="p.profesional.foto" 
                    :alt="`${p.profesional.nombre} ${p.profesional.apellido}`" 
                    class="person-img">
                </td>
                <td>{{ p.profesional.nombre }}</td>
                <td>{{ p.profesional.apellido }}</td>
                <td>
                  <div v-if="p.profesional.empresa">
                    {{ p.profesional.empresa.nombre }}  
                    <img :src="p.profesional.empresa.foto" class="organization-img">
                  </div>
                  <div v-else>
                    No tiene
                  </div>
                </td>
                <td>${{ Intl.NumberFormat('de-DE').format(p.monto_total) }}</td>
                <td>${{ Intl.NumberFormat('de-DE').format(p.monto_a_pagar) }}</td>
                <td>
                  <button 
                    @click="$router.push({ path: `/superadmin/Transferir/${p.profesional.id}` })" 
                    class="transfer-btn">
                    Transferir
                  </button>
                </td>
              </tr>

              <!-- Total Row -->
              <tr class="total-row">
                <td colspan="4"><strong>Total</strong></td>
                <td><strong>${{ Intl.NumberFormat('de-DE').format(totalVenta) }}</strong></td>
                <td><strong>${{ Intl.NumberFormat('de-DE').format(totalAPagar) }}</strong></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <button class="btn btn-std mt-2" @click="downloadCSV()">
            Descargar CSV Transferencia Masiva <b-icon icon="download"></b-icon>
          </button>
        
        </div>
        <div v-else class="articulo-principal">
          Cargando...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props: ['id'],
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false, // Initialize as false until data is loaded
      profesionales: [], // Initialize as an empty array
    };
  },
  created(){
    this.initialize_();
  },
  methods: {
    ...mapActions(['initialize']),
    downloadCSV() {
      console.log("Downloading CSV...");
      const url = `${window.hostname}/api/superadmin/generate_transfer_csv`;

      axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'transfers.csv');
          } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'transfers.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((error) => {
          console.error('Error downloading the CSV file:', error);
          // Optionally, display an error message to the user
        });
    },
    initialize_(){
      this.loaded = false;
      axios.get(`${window.hostname}/api/superadmin/get_pendientes`)
        .then(response => {
          // Log the response to inspect its structure
          console.log('API Response:', response.data);

          // Handle different possible structures of 'profesionales'
          const profesionalesData = response.data.profesionales;

          if (Array.isArray(profesionalesData)) {
            // If 'profesionales' is already an array
            this.profesionales = profesionalesData;
          } else if (profesionalesData && typeof profesionalesData === 'object') {
            // If 'profesionales' is an object, attempt to convert it to an array
            // This handles cases where 'profesionales' might be an object with key-value pairs
            this.profesionales = Object.values(profesionalesData);
            console.warn('Converted profesionales object to array:', this.profesionales);
          } else {
            // If 'profesionales' is neither an array nor an object
            console.error('Unexpected structure of profesionales:', typeof profesionalesData);
            this.profesionales = []; // Fallback to empty array
          }

          this.loaded = true;
        })
        .catch(e => {
          this.loaded = true;
          console.error("Error al cargar profesionales:", e);
          this.profesionales = []; // Fallback to empty array in case of error
        });
    },
  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
    
    // Computed property for total Venta
    totalVenta() {
      if (!Array.isArray(this.profesionales)) {
        console.warn('profesionales is not an array:', this.profesionales);
        return 0;
      }
      return this.profesionales.reduce((sum, p) => sum + Number(p.monto_total || 0), 0);
    },
    
    // Computed property for total A pagar
    totalAPagar() {
      if (!Array.isArray(this.profesionales)) {
        console.warn('profesionales is not an array:', this.profesionales);
        return 0;
      }
      return this.profesionales.reduce((sum, p) => sum + Number(p.monto_a_pagar || 0), 0);
    },
  },
  mounted() {
    if (!this.admin_datos.superadmin) {
      this.$router.push('/');
    }
  },
  watch: {
    // Add any watchers if necessary
  },
}
</script>


<style scoped>
.btn-std{
  background-color: #007bff !important;
}
.organization-img{
  max-width: 100px;
  max-height: 80px;
}
.person-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: rgb(38, 53, 67);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.transfer-btn {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.transfer-btn:hover {
  background-color: #0073a6;
}
.articulo-principal{
  
}
table { 
  margin-left: auto;
  margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;
}
.main-container{
  background-color: white;
  margin-top:10px;
  padding:20px 20px 10px 20px;
}
@media (max-width: 800px) {
 .column-right{
  padding-left:0px;
 }
}

/* Style for the Total row */
.total-row {
  background-color: rgb(38, 53, 67) !important;
  color:white;
  border-top: 1px solid rgb(140, 140, 140);
}
.total-row td {
  font-weight: bold;
}
</style>
