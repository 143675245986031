import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Calendario from '../views/Calendario.vue'
import ModificarHorario from '../views/ModificarHorario.vue'
import Profile from '../views/Profile.vue'
import Ordenes from '../views/Ordenes.vue'
import Mapa from '../views/Mapa.vue'
import VerProfesionales from '../views/VerProfesionales.vue'
import VerProfesional from '../views/VerProfesional.vue'
import ObtenerApp from '../views/ObtenerApp.vue'
import Proximamente from '../views/Proximamente.vue'
import ModificarHorarioPro from '../views/ModificarHorarioPro.vue'
import NuevoHorarioPro from '../views/NuevoHorarioPro.vue'
import NuevoBloqueo from '../views/NuevoBloqueo.vue'
import Reportes from '../views/Reportes.vue'
import ModificarPrecios from '../views/ModificarPrecios.vue'
import ModificarOrden from '../views/ModificarOrden.vue'
import Pagos from '../views/superadmin/Pagos.vue'
import Transferir from '../views/superadmin/Transferir.vue'
import Transferencias from '../views/superadmin/Transferencias.vue'
import AgendaManual from '../views/superadmin/AgendaManual.vue'
import ResponderSolicitudes from '../views/superadmin/ResponderSolicitudes.vue'
import CotizacionManual from '../views/superadmin/CotizacionManual.vue'
import Blog from '../views/superadmin/Blog.vue'
import EditarPost from '../views/superadmin/EditarPost.vue'
import BlogNuevoPost from '../views/superadmin/BlogNuevoPost.vue'
import Aplicante from '../views/superadmin/Aplicante.vue'
import PagoEmpresasAdministracion from '../views/superadmin/PagoEmpresasAdministracion.vue'
import PropertyManagerManagement from '../views/superadmin/PropertyManagerManagement.vue'
import EmpresaAdministracionManagement from '../views/superadmin/EmpresaAdministracionManagement.vue'
import AsignarProfesionales from '../views/superadmin/AsignarProfesionales.vue'
import CrearSolicitud from '../views/administrador_propiedades/CrearSolicitud.vue'
import ProfesionalesAdmPropiedades from '../views/administrador_propiedades/Profesionales.vue'
import Solicitudes from '../views/administrador_propiedades/Solicitudes.vue'
import TransferenciasEmpresa from '../views/administrador_propiedades/TransferenciasEmpresa.vue'
import ModificarVisita from '../views/ModificarVisita.vue'
import Mailing from '../views/superadmin/Mailing.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/AgendaManual',
    name: 'AgendaManual',
    component: AgendaManual,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administrador-propiedades/CrearSolicitud',
    name: 'CrearSolicitud',
    component: CrearSolicitud,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administrador-propiedades/ProfesionalesAdmPropiedades',
    name: 'ProfesionalesAdmPropiedades',
    component: ProfesionalesAdmPropiedades,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administrador-propiedades/TransferenciasEmpresa',
    name: 'TransferenciasEmpresa',
    component: TransferenciasEmpresa,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administrador-propiedades/Solicitudes',
    name: 'Solicitudes',
    component: Solicitudes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/AsignarProfesionales',
    name: 'AsignarProfesionales',
    component: AsignarProfesionales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/CotizacionManual',
    name: 'CotizacionManual',
    component: CotizacionManual,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Aplicante',
    name: 'Aplicante',
    component: Aplicante,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/PropertyManagerManagement',
    name: 'PropertyManagerManagement',
    component: PropertyManagerManagement,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/PagoEmpresasAdministracion',
    name: 'PagoEmpresasAdministracion',
    component: PagoEmpresasAdministracion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/EmpresaAdministracionManagement',
    name: 'EmpresaAdministracionManagement',
    component: EmpresaAdministracionManagement,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/ResponderSolicitudes',
    name: 'ResponderSolicitudes',
    component: ResponderSolicitudes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Mailing',
    name: 'Mailing',
    component: Mailing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/VerProfesionales',
    name: 'VerProfesionales',
    component: VerProfesionales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Pagos',
    name: 'Pagos',
    component: Pagos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Transferencias',
    name: 'Transferencias',
    component: Transferencias,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Blog',
    name: 'Blog',
    component: Blog,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/BlogNuevoPost',
    name: 'BlogNuevoPost',
    component: BlogNuevoPost,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/Transferir/:id',
    name: 'Transferir',
    component: Transferir,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/superadmin/EditarPost/:id',
    name: 'EditarPost',
    component: EditarPost,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/VerProfesional/:id',
    name: 'VerProfesional',
    component: VerProfesional,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ModificarPrecios/:profesional_id/:id?',
    name: 'ModificarPrecios',
    component: ModificarPrecios,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ModificarOrden/:id',
    name: 'ModificarOrden',
    component: ModificarOrden,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ModificarVisita/:id',
    name: 'ModificarVisita',
    component: ModificarVisita,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ObtenerApp',
    name: 'ObtenerApp',
    component: ObtenerApp,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/NuevoBloqueo/:id',
    name: 'NuevoBloqueo',
    component: NuevoBloqueo,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Reportes',
    name: 'Reportes',
    component: Reportes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Proximamente',
    name: 'Proximamente',
    component: Proximamente,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ModificarHorario/:id',
    name: 'ModificarHorario',
    component: ModificarHorario,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/NuevoHorarioPro/:id',
    name: 'NuevoHorarioPro',
    component: NuevoHorarioPro,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ModificarHorarioPro/:id',
    name: 'ModificarHorarioPro',
    component: ModificarHorarioPro,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Mapa',
    name: 'Mapa',
    component: Mapa,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Ordenes',
    name: 'Ordenes',
    component: Ordenes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Calendario',
    name: 'Calendario',
    component: Calendario,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      requiresNotAuth: true
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/Login') 
  }else if(to.matched.some(record => record.meta.requiresNotAuth)){
    if (!store.getters.isLoggedIn) {
      next()
      return
    }
    next('/Home')
  } else {
    next() 
  }
})

export default router
