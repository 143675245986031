<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <ModalNuevoProfesional ref="modalnuevoprofesional" />
      <div class="main-container">       
        <div v-if="loaded" class="articulo-principal">
          <!-- Existing Content -->

          <div class="card shadow border-0 mb-7">
            <div style="background:#FFFFFF;" class="card-header">
              <h5 class="mb-0">Profesionales </h5>
            </div>
            <div class="table-responsive">
              <table class="table table-hover table-nowrap">
                <thead style="background-color: rgb(248, 248, 248)" class="">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Horarios</th>
                    <th scope="col">Calificación</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Contacto</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    style="vertical-align: middle;" 
                    v-for="p in profesionales" 
                    :key="p.profesional.id"
                    :class="{'suspended-row': p.profesional.suspendido == 1}"
                  >
                    <td>
                      <img 
                        style="height:80px; width: 80px;" 
                        :src="(p.profesional.foto && p.profesional.foto != '') ? p.profesional.foto : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'" 
                        alt="Imagen profesional" 
                        class="avatar avatar-sm rounded-circle me-2"
                      >
                      <router-link 
                        :to="'VerProfesional/'+p.profesional.id" 
                        class="text-heading font-semibold"
                      >
                        {{ p.profesional.nombre + ' ' + p.profesional.apellido }}
                      </router-link>
                    </td>
                    <td style="vertical-align: middle;">
                      <div v-for="h in p.horarios" :key="h.id">
                        <span v-for="i in 7" v-if="h.repeticion[i-1] == 1">
                          {{ dias[i-1] }} 
                        </span> 
                        entre {{ timeConvert(h.inicio) + ' - ' + timeConvert(h.termino) }} 
                        en <span v-for="c in h.comunas" :key="c.id">{{ c.nombre }} </span>
                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <div v-if="p.clasificacion[0] == 0">
                        <span style="font-style: italic;">Sin calificaciones</span>
                      </div>
                      <div v-else>
                        <span v-for="s in Math.trunc(p.clasificacion[1]/p.clasificacion[0])" :key="s">
                          <b-icon class="star" icon="star-fill"></b-icon>
                        </span> 

                        <span v-if="Math.trunc(p.clasificacion[1]/p.clasificacion[0]) != p.clasificacion[1]/p.clasificacion[0]">
                          <b-icon class="star" :icon="(p.clasificacion[1]/p.clasificacion[0] - Math.floor(p.clasificacion[1]/p.clasificacion[0])) >= 0.5 ? 'star-half' : 'star'"></b-icon>
                        </span> 

                        <span v-for="s in Math.trunc(5 - p.clasificacion[1]/p.clasificacion[0])" :key="s">
                          <b-icon class="star" icon="star"></b-icon>
                        </span> 
                        <span>({{ (p.clasificacion[1]/p.clasificacion[0]).toFixed(2) }}, {{ p.clasificacion[0] }} ordenes)</span> 
                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <span v-if="p.profesional.atendiendo_orden" class="medalla atendiendo">
                        Atendiendo <b-icon icon="eye"></b-icon>
                      </span>
                      <span v-else class="medalla libre">
                        Libre
                      </span>
                      <!-- Suspended Badge -->
                      <span v-if="p.profesional.suspendido == 1" class="badge badge-suspended">
                        Suspendido
                      </span>
                      <!-- hidden Badge -->
                        <span v-if="p.profesional.oculto == 1" class="badge badge-hidden">
                        oculto
                      </span>
                      <!-- Suspended Badge -->
                      <span v-if="p.profesional.visibilidad_restringida == 1" class="badge badge-restricted">
                        Visibilidad Restringida
                      </span>
                    </td>
                    <td style="vertical-align: middle;">
                      <span class="badge badge-lg badge-dot">
                        <b-icon icon="whatsapp"></b-icon>    
                        <b-icon icon="letter"></b-icon>    
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-end">
                      <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <router-link :to="'VerProfesional/'+p.profesional.id" class="">
                          <b-icon icon="gear"></b-icon>  
                        </router-link>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer border-0">
              <span class="text-muted text-sm">
                Mostrando {{ profesionales.length }} profesionales de los {{ profesionales.length }} encontrados
              </span>
              <div style="margin-top:20px;">
                <button @click="showCrearModal()" class="btn btn-std">Crear un nuevo profesional</button>
                <button @click="openGenerateContractModal()" class="btn btn-std ml-3">Generar Contrato</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="articulo-principal"> cargando... </div>-->
      </div>
    </div>

    <!-- Custom Modal for Generating Contract -->
    <div v-if="isGenerateModalVisible" class="custom-modal-overlay" @click.self="closeGenerateContractModal">
      <div class="custom-modal">
        <h3>Generar Contrato</h3>
        <form @submit.prevent="generateContract">
          <div class="form-group">
            <label for="nombreProfesional">Nombre del Profesional:</label>
            <input 
              type="text" 
              id="nombreProfesional" 
              v-model="contractForm.nombre_profesional" 
              class="form-control" 
              placeholder="Ingrese el nombre completo del profesional" 
              required
            >
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary" :disabled="loading">Descargar Contrato</button>
            <button type="button" class="btn btn-secondary" @click="closeGenerateContractModal" :disabled="loading">Cancelar</button>
          </div>
        </form>
        <div v-if="loading" class="loading-indicator">
          <span>Cargando...</span>
        </div>
        <div v-if="errorMessage" class="error-message">
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Fuse from 'fuse.js';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalNuevoProfesional from '@/components/ModalNuevoProfesional.vue';

export default {
  name: 'HelloWorld',
  props: ['id'],
  components: {
    SideNav,
    ModalNuevoProfesional,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      profesionales: [],
      dias: ['Lu', 'Ma', 'Mi', 'Jue', 'Vi', 'Sa', 'Do'],
      isGenerateModalVisible: false,      // Controls modal visibility
      contractForm: {                     // Holds form data for contract generation
        nombre_profesional: '',
      },
      loading: false,                     // Indicates API call in progress
      errorMessage: '',                   // Stores error messages
    };
  },
  created() {
    this.initialize_();
  },
  methods: {
    ...mapActions(['initialize']),
    showCrearModal() {
      this.$refs.modalnuevoprofesional.show();
    },
    timeConvert(n) {
      const num = n;
      const hours = Math.floor(num / 60);
      const minutes = num % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    initialize_() {
      this.loaded = false;
      axios.get(`${window.hostname}api/admin/getprofesionales`)
        .then(response => {
          this.loaded = true;
          this.profesionales = response.data.profesionales;
        })
        .catch(e => {
          this.loaded = true;
          console.log("Error al cargar...", e);
        });
    },
    // Open the Generate Contract Modal
    openGenerateContractModal() {
      this.contractForm.nombre_profesional = ''; // Reset form
      this.isGenerateModalVisible = true;
      this.errorMessage = '';
    },
    // Close the Generate Contract Modal
    closeGenerateContractModal() {
      this.isGenerateModalVisible = false;
      this.loading = false;
      this.errorMessage = '';
    },
    // Generate the contract by calling the Flask endpoint
    generateContract() {
      const nombre_profesional = this.contractForm.nombre_profesional.trim();
      if (!nombre_profesional) {
        this.errorMessage = "El nombre del profesional es requerido.";
        return;
      }

      this.loading = true;
      this.errorMessage = '';

      // Construct the API URL
      const apiUrl = `${window.hostname}api/superadmin/generate_contract?nombre_profesional=${encodeURIComponent(nombre_profesional)}`;

      // Make the GET request to the Flask endpoint
      axios.get(apiUrl, {
        responseType: 'blob', // Important for handling binary data
      })
      .then(response => {
        // Create a Blob from the PDF Stream
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Create a link element
        const link = document.createElement('a');
        // Set the href to a URL created from the blob
        link.href = window.URL.createObjectURL(blob);
        // Set the download attribute with a filename
        const filename = `acuerdo_${nombre_profesional.replace(/ /g, '_')}.pdf`;
        link.download = filename;
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download by simulating click
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        // Close the modal
        this.closeGenerateContractModal();
      })
      .catch(error => {
        console.error("Error al generar el contrato:", error);
        if (error.response && error.response.data) {
          // Attempt to parse error message from response
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const json = JSON.parse(reader.result);
              this.errorMessage = json.error || "Ocurrió un error al generar el contrato.";
            } catch (e) {
              this.errorMessage = "Ocurrió un error al generar el contrato.";
            }
          };
          reader.readAsText(error.response.data);
        } else {
          this.errorMessage = "Ocurrió un error al generar el contrato. Por favor, inténtalo de nuevo.";
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, { keys: ['Nombre'] });
    this.$root.$on('update-profesionales', () => {      
      this.initialize_();
    });
  },
  watch: {
    comunas_buscadas(val) {
      this.resultado_fuse_comuna = this.fComunas.search(val);
    },
  },
};
</script>


<style scoped>

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-modal h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.loading-indicator {
  margin-top: 10px;
  color: #007bff;
  text-align: center;
}

.error-message {
  margin-top: 10px;
  color: #dc3545;
  text-align: center;
}

.btn-std {
  margin-right: 10px; /* Adjust spacing as needed */
}



.star{
  cursor: pointer;
  margin-right:4px;
  height:18px;
  width: 18px;
  color:#DAA520;
  /*color:#DAA520;*/
}

.medalla{
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
  width:100px;
  display:inline-block;
  text-align: center;
}

.atendiendo{
  background-color: #2ECC40;
  color:#FFFFFF;
}

.libre{
  background-color: #0074D9;
  color:#FFFFFF;
}

.badge-suspended {
  display: inline-block;
  background-color: #e74c3c; /* Red color for suspension */
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
}
.badge-hidden {
  display: inline-block;
  background-color: #422db4; /* Red color for suspension */
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
}
.badge-restricted {
  display: inline-block;
  background-color: rgb(40, 40, 40); 
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
}

.suspended-row {
  background-color: #f8d7da; /* Light red background */
}

td{
  text-align: left;
}

.articulo-principal{

}

table
{ 
    margin-left: auto;
    margin-right: auto;
}

.column-right{
  background-color: rgb(242, 247, 251);
  min-height:133vh;
  padding-top:80px;
  padding-left:260px;
  width:100%;
}

.main-container{
  background-color: white;
  margin-top:10px;
}

@media (max-width: 800px) {
 .column-right{
  padding-left:0px;
 }
}
</style>
