<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div class="main-container">
        <div class="articulo-principal">
          <h2>Crear Nueva Solicitud</h2>
          <form @submit.prevent="crearSolicitud" enctype="multipart/form-data">
            <!-- Información del Usuario -->
            <div class="sectionv">
              <h3>Información del Usuario 🧑</h3>
              <div class="form-group">
                <label>Nombre:</label>
                <input v-model="usuario.nombre" type="text" required>
              </div>
              <div class="form-group">
                <label>Email:</label>
                <input v-model="usuario.email" type="email" required>
              </div>
              <div class="form-group">
                <label>Teléfono:</label>
                <input v-model="usuario.telefono" type="text" required>
              </div>
            </div>
            <!-- Información de la Solicitud -->
            <div class="sectionv">
              <h3>Detalles de la Solicitud 📝</h3>
              <div class="form-group">
                <label>Título:</label>
                <input v-model="solicitud.titulo" type="text" required>
              </div>
              <div class="form-group">
                <label>Descripción del Problema:</label>
                <textarea v-model="solicitud.descripcion" required></textarea>
              </div>
              
              <!-- DIRECCIÓN with Autocomplete -->
              <div class="form-group" style="position: relative;">
                <label style="display: inline-block">Dirección:</label> (debes cliquear una dirección de la lista)
                <input 
                  v-model="solicitud.direccion" 
                  type="text" 
                  required 
                  @input="onInput"
                  placeholder="Empieza a escribir la dirección..."
                >
                <ul v-if="suggestions.length > 0" style="list-style:none; position:absolute; background:white; border:1px solid #ccc; width:100%; max-height:150px; overflow-y:auto; margin:0; padding:0; z-index:999;">
                  <li 
                    v-for="(suggestion, index) in suggestions" 
                    :key="index"
                    @click="selectSuggestion(suggestion)"
                    style="padding:8px; cursor:pointer; border-bottom:1px solid #eee;"
                  >
                    {{ suggestion.description }}
                  </li>
                </ul>
              </div>

              <div class="form-group">
                <label>Comuna:</label>
                <select v-model="solicitud.comuna_id" required>
                  <option disabled value="">Seleccione una comuna</option>
                  <option v-for="comuna in comunas" :key="comuna.id" :value="comuna.id">
                    {{ comuna.Nombre }}
                  </option>
                </select>
              </div>

              <!-- Nuevo campo: Tipo de Propiedad -->
              <div class="form-group">
                <label>Tipo de Propiedad:</label>
                <select v-model="solicitud.tipo_propiedad" required>
                  <option disabled value="">Seleccione el tipo de propiedad</option>
                  <option value="Casa">Casa</option>
                  <option value="Departamento">Departamento</option>
                </select>
              </div>

              <!-- Campo Condicional: Número de Apartamento -->
              <div class="form-group" v-if="solicitud.tipo_propiedad === 'Departamento'">
                <label>Número de Apartamento:</label>
                <input v-model="solicitud.numero_apartamento"  required>
              </div>

              <div class="form-group">
                <label>Categoría:</label>
                <select v-model="solicitud.categoria_id" required>
                  <option disabled value="">Seleccione una categoría</option>
                  <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">
                    {{ categoria.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Prioridad:</label>
                <select v-model="solicitud.prioridad" required>
                  <option disabled value="">Seleccione una prioridad</option>
                  <option value="alta">Alta</option>
                  <option value="media">Media</option>
                  <option value="baja">Baja</option>
                </select>
              </div>
              <div class="form-group">
                <label>Estado de la Propiedad:</label>
                <select v-model="solicitud.estado" required>
                  <option disabled value="">Seleccione el estado de la propiedad</option>
                  <option value="habitada">Habitada</option>
                  <option value="inhabitada">Inhabitada</option>
                  <option value="desconocido">Desconocido</option>
                </select>
              </div>
              <div class="form-group ubicacion-llaves">
                <label>Ubicación de llaves:</label>
                <div>
                  <label>
                    <input type="radio" v-model="solicitud.ubicacion_llaves" value="Arrendatario"> Arrendatario
                  </label>
                  <label>
                    <input type="radio" v-model="solicitud.ubicacion_llaves" value="Propietario"> Propietario
                  </label>
                  <label>
                    <input type="radio" v-model="solicitud.ubicacion_llaves" value="Conserjería"> Conserjería
                  </label>
                  <label>
                    <input type="radio" v-model="solicitud.ubicacion_llaves" value="Corredora"> Corredora
                  </label>
                  <label>
                    <input type="radio" v-model="solicitud.ubicacion_llaves" value="Otro"> Otro
                  </label>
                </div>
                <div v-if="solicitud.ubicacion_llaves === 'Otro'" class="form-group">
                  <input v-model="solicitud.ubicacion_llaves_otro" type="text" placeholder="Especifique otra ubicación" required>
                </div>
              </div>
              <div class="form-group">
                <label>Fotos:</label>
                <input type="file" multiple @change="onFileChange">
              </div>
            </div>

            <button type="submit" class="btn btn-std">Crear Solicitud <b-icon icon="star"></b-icon></button>
          </form>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>

    <!-- Modal for ongoing solicitud -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <div style="text-align: center; font-size: 30px !important; ">
          <h3><b-icon style="display: inline-block; height: 20px; width: 20px;" icon="exclamation-circle"></b-icon> Advertencia  </h3>
        </div>
        <h3>Ya existe una solicitud activa para esta dirección</h3>
        <p><strong>Título:</strong> {{ ongoingSolicitud.titulo }}</p>
        <p><strong>Fecha:</strong> {{ new Date(ongoingSolicitud.fecha_creado_iso).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}</p>
        <p><strong>Descripción:</strong> {{ ongoingSolicitud.descripcion }}</p>
        <!-- Add more details as needed -->
        <button @click="closeModal">Cerrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'CrearSolicitud',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      usuario: {
        nombre: '',
        email: '',
        telefono: '',
      },
      solicitud: {
        titulo: '',
        descripcion: '',
        categoria_id: '',
        comuna_id: '',
        prioridad: '',
        direccion: '',
        estado: '',
        ubicacion_llaves: '',
        ubicacion_llaves_otro: '',
        tipo_propiedad: '',          // Nuevo campo
        numero_apartamento: '',     // Nuevo campo
      },
      fotos: [], 
      isLoading: false,
      suggestions: [],
      latitude: null,
      longitude: null,
      place_id: null,
      ongoingSolicitud: null,
      showModal: false
    };
  },
  computed: {
    ...mapGetters(['admin_datos', 'categorias', 'comunas']),
  },
  methods: {
    onFileChange(e) {
      this.fotos = Array.from(e.target.files);
    },
    onInput() {
      const query = this.solicitud.direccion.trim();
      if (query === '') {
        this.suggestions = [];
        return;
      }

      axios.get(window.hostname + `api/property_manager/autocomplete?input=${encodeURIComponent(query)}`)
        .then(response => {
          this.suggestions = response.data || [];
        })
        .catch(() => {
          this.suggestions = [];
        });
    },
    selectSuggestion(suggestion) {
      axios.get(window.hostname + `api/property_manager/place_details?place_id=${encodeURIComponent(suggestion.place_id)}`)
      .then(response => {
          const data = response.data;
          
          // Extract address components
          const addressComponents = data.address_components || [];
          const routeComp = addressComponents.find(c => c.types.includes('route'));
          const routeName = routeComp ? routeComp.long_name : '';
          const streetNumComp = addressComponents.find(c => c.types.includes('street_number'));
          const streetNumber = streetNumComp ? streetNumComp.long_name : '';
          
          // Build the full address from street number + route
          const fullAddress = `${routeName} ${streetNumber}`.trim();
          
          // Extract comuna from administrative_area_level_3
          const comunaComp = addressComponents.find(c => c.types.includes('administrative_area_level_3'));
          if (comunaComp) {
            const comunaName = comunaComp.long_name;
            const selectedComuna = this.comunas.find(x => x.Nombre === comunaName);
            if (selectedComuna) {
              this.solicitud.comuna_id = selectedComuna.id;
            }
          }
          
          // Extract lat/lng from geometry
          let latitude = null;
          let longitude = null;
          if (data.geometry && data.geometry.location) {
            latitude = data.geometry.location.lat;
            longitude = data.geometry.location.lng;
          }

          // Extract place_id
          const placeId = data.place_id || null;
          
          // Update component state
          this.solicitud.direccion = fullAddress;
          this.latitude = latitude;
          this.longitude = longitude;
          this.place_id = placeId;

          // Clear suggestions after selection
          this.suggestions = [];

          // Check for ongoing solicitud with this place_id
          if (this.place_id) {
            this.checkOngoingSolicitud(this.place_id);
          }
        })
        .catch(() => {
          console.error('Error retrieving place details');
        });
    },
    checkOngoingSolicitud(place_id) {
      axios.get(window.hostname + `api/property_manager/check_ongoing?place_id=${encodeURIComponent(place_id)}`)
        .then(response => {
          if (response.data && response.data.ongoing) {
            this.ongoingSolicitud = response.data.solicitud;
            this.showModal = true;
          } else {
            this.ongoingSolicitud = null;
            this.showModal = false;
          }
        })
        .catch(e => {
          console.error('Error checking ongoing solicitud', e);
        });
    },
    closeModal() {
      this.showModal = false;
    },
    crearSolicitud() {
      // Validaciones existentes
      if (
        !this.usuario.nombre ||
        !this.usuario.email ||
        !this.usuario.telefono ||
        !this.solicitud.titulo ||
        !this.solicitud.descripcion ||
        !this.solicitud.categoria_id ||
        !this.solicitud.comuna_id ||
        !this.solicitud.direccion ||
        !this.solicitud.prioridad ||
        !this.solicitud.estado ||
        !this.solicitud.ubicacion_llaves ||
        !this.solicitud.tipo_propiedad    // Validación para tipo de propiedad
      ) {
        alert('Por favor, complete todos los campos obligatorios.');
        return;
      }

      // Validación adicional si es Departamento
      if (this.solicitud.tipo_propiedad.toLowerCase() === 'departamento' && !this.solicitud.numero_apartamento) {
        alert('Por favor, ingrese el número de apartamento.');
        return;
      }

      // Validación existente para "Otro" en ubicación de llaves
      if (this.solicitud.ubicacion_llaves === 'Otro' && !this.solicitud.ubicacion_llaves_otro) {
        alert('Por favor, especifique la ubicación de llaves.');
        return;
      }

      this.isLoading = true;

      const formData = new FormData();
      // Datos del usuario
      formData.append('nombre', this.usuario.nombre);
      formData.append('email', this.usuario.email);
      formData.append('telefono', this.usuario.telefono);
      // Datos de la solicitud
      formData.append('titulo', this.solicitud.titulo);
      formData.append('descripcion', this.solicitud.descripcion);
      formData.append('categoria_id', this.solicitud.categoria_id);
      formData.append('comuna_id', this.solicitud.comuna_id);
      formData.append('prioridad', this.solicitud.prioridad);
      formData.append('direccion', this.solicitud.direccion);
      formData.append('estado', this.solicitud.estado);
      formData.append('tipo_propiedad', this.solicitud.tipo_propiedad); // Añadir tipo de propiedad

      // Número de apartamento si es Departamento
      if (this.solicitud.tipo_propiedad.toLowerCase() === 'departamento') {
        formData.append('numero_apartamento', this.solicitud.numero_apartamento);
      }

      // Ubicación de llaves
      if (this.solicitud.ubicacion_llaves === 'Otro') {
        formData.append('ubicacion_llaves', this.solicitud.ubicacion_llaves_otro);
      } else {
        formData.append('ubicacion_llaves', this.solicitud.ubicacion_llaves);
      }

      // Lat/Long and Place ID
      if (this.latitude !== null && this.longitude !== null) {
        formData.append('lat', this.latitude);
        formData.append('lng', this.longitude);
      }

      if (this.place_id) {
        formData.append('place_id', this.place_id);
      }

      // Fotos
      this.fotos.forEach((foto, index) => {
        formData.append(`foto${index}`, foto);
      });

      axios
        .post(window.hostname + 'api/property_manager/crear_solicitud', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: '¡Solicitud creada exitosamente!',
            type: 'success',
          });
          this.$router.push('/administrador-propiedades/Solicitudes');
        })
        .catch((e) => {
          this.isLoading = false;
          console.log('Error al crear la solicitud', e);
          alert('Ocurrió un error al crear la solicitud');
        });
    },
  },
  mounted() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'property_manager') {
      this.$router.push('/');
    }
  },
};
</script>


<style scoped>
/* Modal styles for example */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,0.4);
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:10000;
}

.modal-content {
  background:white;
  padding:20px;
  border-radius:8px;
  max-width:500px;
  width:100%;
}

/* Estilos similares a los de tus componentes existentes */
.ubicacion-llaves {
  max-width: 300px;
}
.ubicacion-llaves input[type="radio"] {
  width: 20px !important;
}
.btn-std {
  background-color: #007bff !important;
}

h2 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.sectionv {
  background-color: rgb(250, 250, 250);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn-save {
  background-color: #28a745;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save:hover {
  background-color: #218838;
}

.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}

.crear-solicitud-container {
  display: flex;
  flex-direction: column;
  min-height: 133vh;
}

.content-wrapper {
  display: flex;
  flex: 1;
}

.main-content {
  flex: 1;
  background-color: #f0f2f5;
  padding: 80px 20px 20px;
}

.form-container {
  background-color: white;
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.form-container h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #3498db;
  outline: none;
}

.form-group textarea {
  resize: vertical;
}

.form-group input[type='file'] {
  padding: 3px;
}

.btn-submit {
  display: block;
  width: 100%;
  background-color: #3498db;
  color: white;
  padding: 14px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.btn-submit:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .main-content {
    padding: 20px;
  }

  .form-container {
    padding: 30px;
  }
}

/* Estilos para la pantalla de carga */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparente negro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 12px solid #f3f3f3; /* Gris claro */
  border-top: 12px solid #3498db; /* Azul */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
