<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div v-if="admin_datos && admin_datos.user_type === 'jobbing_admin'" class="main-container">
        <div class="articulo-principal">
          <h2>Asignar Profesionales a Empresa de Administración</h2>
          <div v-if="loaded">
            <!-- Empresa Selection -->
            <div class="form-group">
              <label for="empresa-select">Seleccione una Empresa de Administración:</label>
              <select id="empresa-select" v-model="selectedEmpresaId" @change="onEmpresaChange">
                <option disabled value="">Seleccione una empresa</option>
                <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                  {{ empresa.nombre }}
                </option>
              </select>
            </div>

            <!-- Profesionales Selection -->
            <div v-if="selectedEmpresaId">
              <h3>Seleccione los Profesionales y Asigne la Comisión</h3>
              <div class="profesionales-list">
                <div
                  v-for="profesional in profesionales"
                  :key="profesional.id"
                  class="profesional-item"
                >
                  <!-- Checkbox for Selection -->
                  <input
                    type="checkbox"
                    :id="'profesional-' + profesional.id"
                    :value="profesional.id"
                    v-model="selectedProfesionalesIds"
                  />
                  <label :for="'profesional-' + profesional.id">
                    {{ profesional.nombre }} {{ profesional.apellido }} ({{ profesional.email }})
                  </label>

                  <!-- Comisión Input Field -->
                  <div v-if="selectedProfesionalesIds.includes(profesional.id)" class="comision-input">
                    <label :for="'comision-' + profesional.id">Comisión (%) :</label>
                    <input
                      type="number"
                      :id="'comision-' + profesional.id"
                      v-model.number="selectedComisiones[profesional.id]"
                      min="0"
                      max="100"
                      step="0.01"
                      placeholder="Ej: 10.5"
                    />
                  </div>
                </div>
              </div>
              <button class="btn-submit" @click="asignarProfesionales">Asignar Profesionales</button>
            </div>
          </div>
          <div v-else>
            Cargando datos...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'AsignarProfesionales',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      empresas: [],
      profesionales: [],
      selectedEmpresaId: '',
      selectedProfesionalesIds: [],
      selectedComisiones: {}, // Tracks comision for each selected profesional
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  methods: {
    fetchData() {
      this.loaded = false;
      axios
        .all([
          axios.get(window.hostname + 'api/admin/get_empresas_administracion'),
          axios.get(window.hostname + 'api/admin/get_profesionales'),
        ])
        .then(
          axios.spread((empresasRes, profesionalesRes) => {
            this.empresas = empresasRes.data.empresas;
            this.profesionales = profesionalesRes.data.profesionales;
            this.loaded = true;
          })
        )
        .catch((e) => {
          console.log('Error al cargar datos:', e);
          this.loaded = true;
        });
    },
    onEmpresaChange() {
      // Reset selected profesionales and comisiones
      this.selectedProfesionalesIds = [];
      this.selectedComisiones = {};

      // Al cambiar la empresa seleccionada, obtener los profesionales asignados actualmente junto con sus comisiones
      axios
        .get(window.hostname + 'api/admin/get_profesionales_empresa', {
          params: { empresa_id: this.selectedEmpresaId },
        })
        .then((response) => {
          // Assuming the response data structure is:
          // { profesionales: [ { id: 1, comision: 10.5 }, { id: 2, comision: 15.0 }, ... ] }
          if (response.data.profesionales && Array.isArray(response.data.profesionales)) {
            response.data.profesionales.forEach((profesional) => {
              this.selectedProfesionalesIds.push(profesional.id);
              // Initialize comision with existing value or default to 0
              this.$set(this.selectedComisiones, profesional.id, profesional.comision || 0);
            });
          }
        })
        .catch((e) => {
          console.log('Error al obtener profesionales de la empresa:', e);
        });
    },
    asignarProfesionales() {
      if (!this.selectedEmpresaId) {
        alert('Seleccione una empresa de administración.');
        return;
      }

      if (this.selectedProfesionalesIds.length === 0) {
        alert('Seleccione al menos un profesional.');
        return;
      }

      // Validate that all selected profesionales have a comision value
      for (let id of this.selectedProfesionalesIds) {
        const comision = this.selectedComisiones[id];
        if (comision === undefined || comision === null || comision === '') {
          alert(`Por favor, ingrese una comisión para el profesional con ID ${id}.`);
          return;
        }
        if (isNaN(comision) || comision < 0 || comision > 100) {
          alert(`La comisión para el profesional con ID ${id} debe ser un número entre 0 y 100.`);
          return;
        }
      }

      // Prepare data to send
      const profesionalesConComision = this.selectedProfesionalesIds.map((id) => ({
        id: id,
        comision: this.selectedComisiones[id],
      }));

      axios
        .post(window.hostname + 'api/admin/asignar_profesionales', {
          empresa_id: this.selectedEmpresaId,
          profesionales: profesionalesConComision,
        })
        .then(() => {
          alert('Profesionales asignados correctamente.');
          // Optionally, refresh the data to reflect changes
          this.onEmpresaChange();
        })
        .catch((e) => {
          console.log('Error al asignar profesionales:', e);
          alert('Ocurrió un error al asignar los profesionales.');
        });
    },
  },
  mounted() {
    if (!this.admin_datos || this.admin_datos.user_type !== 'jobbing_admin') {
      this.$router.push('/');
    } else {
      this.fetchData();
    }
  },
};
</script>


<style scoped>
.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}

.main-container {
  background-color: white;
  margin-top: 10px;
  padding: 20px;
}

.articulo-principal h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.profesionales-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
}

.profesional-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.profesional-item label {
  margin-left: 5px;
}

.comision-input {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.comision-input label {
  margin-right: 5px;
}

.comision-input input {
  width: 80px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.btn-submit {
  background-color: #3498db;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.btn-submit:hover {
  background-color: #2980b9;
}

@media (max-width: 800px) {
  .column-right {
    padding-left: 20px;
  }

  .comision-input {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 5px;
  }

  .comision-input input {
    width: 100%;
  }
}
</style>
