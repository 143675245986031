<template>
  <transition name="fade">
    <div 
      v-if="visible" 
      class="modal-overlay" 
      @click.self="close"
      role="dialog" 
      aria-modal="true"
    >
      <div class="modal-content">
        <button class="close-button" @click="close" aria-label="Close Modal">&times;</button>
        <img :src="imageSrc" alt="Enlarged Image" class="modal-image" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ImagePreviewModal',
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleEsc(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleEsc);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEsc);
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10040;
}

.modal-content {
  position: relative;
  max-width: 90vw; /* 90% of viewport width */
  max-height: 90vh; /* 90% of viewport height */
  padding: 0; /* Removed padding */
  box-sizing: border-box;
  overflow: hidden; /* Prevents scrollbars */
  display: flex; /* Centers content */
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Optional: adds a background behind the image */
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  display: block;
}

.close-button {
  position: absolute;
  top: 10px; /* Adjusted to be inside the modal */
  right: 10px; /* Adjusted to be inside the modal */
  color: white;
  background-color: #ff5c5c;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
