<template>
   <div>
     
   <transition name="zoom">
   <div v-if="showing" id="modal-receta-2" >
   
   
      <div class="main-cont">
         <div v-on:click="hide();" class="contenedor-icono-2">       
               <b-icon class="icon" icon="x"></b-icon>
            </div>
         <div class="header-m">
   
   
   
         </div>
         <div  class="body-m" style="text-align:  left; width:100%;">
            <div v-if="status==0">
               <div class="titulo1">Crear nuevo profesional</div>
            <div class="main-txt">
              <table style="margin:0 auto;">
               <tr>
                  <td>Nombre</td>
                  <td> <input v-model="nombre_" type="text"></td>
               </tr>
               <tr>
                  <td>Apellido</td>
                  <td> <input v-model="apellido_" type="text"></td>
               </tr>
               <tr>
                  <td>Email</td>
                  <td> <input v-model="email_" type="text"></td>
               </tr>
               <tr>
                  <td>Telefono</td>
                  <td> <input v-model="telefono_" type="text"></td>
               </tr>
               <tr>
                  <td>Rut</td>
                  <td> <input v-model="rut_" type="text"></td>
               </tr>
              </table>
            </div>

     
               <div style="text-align: center;">
                  <button  v-on:click="enviar()" :class="{'disable':puedeContinuar != ''}" class="btn-agregar">Crear Profesional             <div
             
              class="spinner-border spinner-border spinner-border-sm"
              style="
                margin-left: 10px;
                margin-right: 14px;
                margin-top: 2px;
                margin-bottom: 2px;
                color: #fff;
              "
              v-if="loading"
              role="status"
            ></div></button>
               </div>
            </div>
            <div v-else-if="status == 1">
               <div class="titulo1">¡Exito!</div>
            <div class="main-txt">
               ¡El profesional se ha creado! Sus credenciales han sido enviadas por email.
            </div>

            </div>
            <div v-else-if="status == 2">
               <div class="titulo1">¡ERROR!</div>
            <div class="main-txt">
               Ha habido un error al crear el profesional.
            </div>

            </div>
               
    

      
         </div>
   


      </div>
   
   </div>
   
   </transition>
   </div>
   </template>
   
   <script>
   import {mapActions, mapGetters} from 'vuex';
   import axios from 'axios'
   
   export default {
     name: 'ModalNuevoProfesional',
     components: {
   
     },
     props: {
      //profesional_: Object,
     },
     data(){
       return {
         nombre_:'',
         apellido_:'',
         telefono_:'',
         email_:'',
         rut_:'',

         loading:false,
         showing:false,
         status: 0,
       }
     },
     methods:{
   
      ...mapActions(['setProfesionalAction']),
   
      enviar(){
         if(this.puedeContinuar != '')
            return;
      this.loading = true;
      axios.post(window.hostname+'api/admin/crear_profesional',
      {
         nombre:this.nombre_,
         apellido:this.apellido_,
         telefono:this.telefono_,
         email:this.email_,
         rut:this.rut_,
      })
      .then(r=>{
         this.status = 1;
         this.loading = false;
         this.$root.$emit('update-profesionales');

      })
      .catch(e=>{
         this.status = 2;
         this.loading = false;

      }); 

      },

      verificar_rut(r)
    {
      function dv(T){
        var M=0,S=1;
        for(;T;T=Math.floor(T/10))
          S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
      }
    function validaRut (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
          return false;
        var tmp 	= rutCompleto.split('-');
        var digv	= tmp[1]; 
        var rut 	= tmp[0];
        if ( digv == 'K' ) digv = 'k' ;
          return (dv(rut) == digv ) ? true : false;
      }
      return validaRut(r)
    },
      verificar_correo(c){
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(c != '')
        return re.test(c) ? true: false;
      return false;
    },

   
       show(){
         this.showing = true;
         this.status = 0;
       },
       hide(){
         this.status = 0;
         
         this.showing = false;
         this.servicio_id = -1;
       }
   
   
     },
     mounted(){
   
   
   
   
      
     },
     created(){
   
     
     },
     computed:{
      ...mapGetters(['agregando_servicio_id', 'servicios', 'servicio_selected']),
      puedeContinuar(){
         if(this.nombre_.length  == 0 || !this.nombre_)
        return 'Debes ingresar un nombre.' 
        if(this.telefono_.length < 4)
        return 'Por favor, ingresa un teléfono valido.'
      if(this.apellido_.length == 0)
        return 'Debes ingresar un apellido.' 
         if(!this.verificar_correo(this.email_))
         return 'Debes ingresar un correo valido.'

         if(!(this.verificar_rut(this.rut_)))
           return 'tiene que tener un rut válido.'  

      return ''
      },
   
     },
     watch:{

     
     },
   }
   </script>
   
   
   
   <style scoped>
   td{
      padding: 5px !important;
   }
   .disable{
      background-color: rgb(148, 148, 148) !important;

   }
   .main-txt{
      text-align: center;
      padding: 20px;
   }
   .left-dv, .right-dv{
      display: inline-block;
      
      width: 50%;
      text-align: center;
      height:80px;

   }
   .right-dv{
      display: inline-block;
   }
   .calificacion{
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      
   
   }

   .empresa-link{
     display: inline-block;
     color:#ffac46;
   }
   .medalla-cont{
     margin-bottom: 8px;
   }
   .medallas{
     margin-bottom: 20px;
   }
   .icons-desc{
     margin-right: 10px;
   }
   .star{
     cursor: pointer;
     margin-right:4px;
     height:18px;
     width: 18px;
     color:#DAA520;
     /*color:#DAA520;*/
   }
   .titulo-perfil{
      font-size: 25px;
     font-weight: 700;
     margin-top:10px;
     margin-bottom: 25px;
     color:rgb(46, 44, 54);
     font-weight: 700;
   }
   .about-cont{
      padding: 20px;
   }
   .titulo1{
      color:rgb(46, 44, 54);
     font-weight: 700;
     margin-bottom: 10px;
     text-align: center;
     font-size: 27px;
     padding: 10px;
   }
   
   .coment-grid{
      display: grid;  
   
      grid-template-columns: repeat(6, 1fr);
      
   
   }
   .coment-l{
      grid-column:  1 / 2; 
      text-align: left;
      min-width: 145px;
      
      
   }
   .coment-r{
      grid-column:  2 / 7; 
      padding-left: 40px;
      
      
   }
   .coment-r .titulo1{
      margin-bottom:30px;   
   }
   .coment-l .titulo1{
      margin-bottom:30px;   
   }
   .perfil-cont{
      display: grid;  
      padding:20px;
      grid-template-columns: repeat(6, 1fr);
   
   }
   .perfil-cont-l{
      grid-column:  1 / 2; 
      
   }
   .perfil-cont-l img{
      height: 150px;
      width: 150px;
      border-radius: 100%;
   }
   .perfil-cont-r{
      padding-left:20px;
      grid-row:1;
     grid-column:  2 / 7; 
   
   }
   .separador{
     width: Calc(100% - 40px);
   
      margin: 20px auto;
     
     border-bottom: 1px solid rgb(240, 240, 240);
   }
   .cont-outer{
      padding: 10px;
   }
   .info-cont{
      padding:10px;
     
      border-radius: 10px;
   }
   .desc-titulo{
    color:rgb(46, 44, 54) !important;
    font-weight: 700;
    font-size: 20px;
    padding: 0px 0px 0px 20px;
    
   }
   .grid-right-container{
      border-right: 1px solid rgb(240, 240, 240);
      margin-top:30px;
      margin-right:20px;
      margin-bottom:20px;
   }
   .desc-body{
      padding: 20px 20px;
   
   }
   .desc-img{
      border-radius: 30px;
      width: auto;
      margin: 0 auto;
      max-height: 300px;
      max-width: 100%;
   }
   .desc-img-cont{
      padding: 20px 20px;
      
   }
   .caract-titulo{
      color: rgb(46, 44, 54) !important;
      font-weight: 700;
      padding: 20px 0px;
   }
   .caract-item{
      font-size: 16px !important;
   }
   .grid-left{
      grid-area: r;
      min-width: 400px;
      margin-left:20px;
      padding:10px 20px;
      
   }
   .grid-right{
      grid-area: l;
   
   }
   .precio-container{
      display: inline-block;
      margin-right: 20px;
   }
   .btn-agregar{
      text-align: center;
      width:240px;
      outline: none;
      border: none;
      display: inline-block;
      padding: 15px 24px;
      font-weight: 700;
      color:#fff;
      background-color: rgb(255,140,0);
      vertical-align: middle;
      border-radius: 8px;
      line-height: 20px;
      transition: .3s;
   }
   .btn-agregar:hover{
      background-color: rgba(255, 140, 0, 0.774);
   }
   .desactivado{
      color:rgb(170, 170, 170);
      background-color: rgb(240,240,240);
      cursor: not-allowed;
   
   }
   .body-m::-webkit-scrollbar-thumb {
     background-color: #d6dee1 !important;
     border-radius: 20px !important;
     border: 6px solid transparent !important;
     background-clip: content-box !important;
   }
   .body-m::-webkit-scrollbar {
     width: 20px;
   }
   
   .body-m::-webkit-scrollbar-track {
     background-color: transparent;
   }
   
   .body-m::-webkit-scrollbar-thumb {
     background-color: #d6dee1;
     border-radius: 20px;
     border: 6px solid transparent;
     background-clip: content-box;
   }
   
   .body-m::-webkit-scrollbar-thumb:hover {
     background-color: #a8bbbf;
   }
   /* Create a custom radio button */
   .footer-m{
   height:70px;
   border-top: 1px solid rgba(160, 160, 160, 0.2);
   padding-top:15px;
   
   }
   .footer-options{
      vertical-align: middle;
      
   }
   .header-m{
      text-align: left; 
      color:rgb(46, 44, 54);
      font-weight: 800;
      font-size: 24px; 
      padding: 10px 20px;
      width:100%;
      display: block;
      height:50px;
      line-height: 65px;
   
      position: relative;
   
   }
   .body-m{
      overflow-y: scroll;
   

   
      
   }
   
   .bg-negro{
     height: 133vh;
     width: 133vw;
     position: fixed;
     background: rgba(0,0,0,0.2);
     /* backdrop-filter: blur(1px);*/
     z-index: 9999;
   
   }
   .main-cont{
      position: relative;   
   }
   
   #modal-receta-2{
      line-height: 16px;
      transition: 1s;
      position:fixed;
      height:Calc(380px);
    
      overflow-y:auto; 
      border-radius:6px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
      background:white; 
      top: 50%;
      left:0px;
       transform: translateY(-50%);
     
      width:Calc(700px);
      max-width:1000px;
      margin: 0px auto; 
      right: 0;
      opacity:1;
      z-index:99999;
   }
   
   
   .cont{
     text-align: center;
     vertical-align: middle;
     position:absolute;
      width:Calc(100% - 40px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
   #modal-receta-2 .icon{
      height:30px; width:30px; color:white;  
   }
   #modal-receta-2 .contenedor-icono-2{
      cursor:pointer;
      position:absolute;
      right:20px;
      top:30px;
      transform: translateY(-50%);
      height:30px;
      width:30px;
      background-color:rgb(100, 100, 100);
      border-radius:50px;
      z-index: 100001;
   }
   
   @media only screen and (max-width: 370px) {
      .calificacion{
      display: inherit !important;
      grid-template-columns: none;
      
   
   }
   .calificacion-l-stars{
      margin-bottom: 10px;
   }
   .calificacion-l-nombre{
      font-size: 16px !important;
      padding: 5px 0px !important;
   
   }
   .calificacion-l{
      grid-column:  1 / 3; 
      text-align: center !important;
      padding: 0px;
         
      
   }
   .calificacion-l .star{
      height: 16px !important;
      width: 16px !important;
      margin-right:2px;
      
      
   }
   .calificacion-l img{
      height:80px;
      width: 80px;
      border-radius: 100%;
   }
   .calificacion-r{
      grid-column:  3 / 9; 
   
      line-height: 100%;
      display: flex;
   
   align-items: center; /* Align vertical */
      
   
      
   }
   .calificacion-r-txt{
      text-align: center;
      
   
   }
   
   
   
   }
   @media only screen and (max-width: 580px) {
   
   
   
      .info-cont{
      padding:10px;
     
      border-radius: 10px;
   }
      .perfil-cont{
      display: inherit;  
      padding:10px;
      text-align: center;
   
   }
   .perfil-cont-r{
      padding-left:0px;
   
   
   }
   .about-cont{
      padding: 5px;
   }
   .cont-outer{
   padding: 0px;
   }
   .perfil-cont-l{
      grid-column:  none;
      margin-bottom: 20px; 
      
   }
   .perfil-cont-l img{
      height: 180px;
      width: 180px;
      border-radius: 100%;
   }
   .perfil-cont-r{
      padding-left:0px;
      grid-row:none;
     grid-column:  none;
     text-align: left; 
   
   }
   
   }
   @media only screen and (max-width: 500px) {
   
      .calificacion{
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      
   
   }
   .calificacion-l-nombre{
      font-size: 14px;
      padding: 10px 0px;
   
   }
   .calificacion-l{
      grid-column:  1 / 3; 
      text-align: left;
      padding: 0px;
         
      
   }
   .calificacion-l .star{
      height: 10px;
      width: 10px;
      margin-right:2px;
      
      
   }
   .calificacion-l img{
      height:80px;
      width: 80px;
      border-radius: 100%;
   }
   .calificacion-r{
      grid-column:  3 / 9; 
   
      line-height: 100%;
      display: flex;
   
   align-items: center; /* Align vertical */
      
   
      
   }
   .calificacion-r-txt{
      vertical-align: middle;
     justify-content: center;
      
   
   }
   }
   @media only screen and (max-width: 740px) {
   
   
      .coment-grid{
      display: inherit;  
   
      grid-template-columns: repeat(6, 1fr);
      
   
   }
   .coment-l{
      grid-column: none; 
      text-align: left;
      min-width: 145px;
      
      
   }
   .coment-r{
      grid-column: none; 
      padding-left: 0px;
      margin-top: 40px;
      
      
   }
   }
   
   @media only screen and (max-width: 1000px) {
   
      .zoom-enter-active,
   .zoom-leave-active {
     animation-duration: 0.0s;
     animation-fill-mode: both;
     animation-name: zoom;
   }
   
   .zoom-leave-active {
     animation-direction: reverse;
   }
      .grid-left{
      grid-area: r;
      min-width: 00px;
      padding-bottom:60px;
   }
   .precio-container{
      display: inline-block;
      margin-right: 10px;
   }
   .grid-right-container{
      border-right: none;
      margin-top:30px;
      margin-right:0px;
      margin-bottom:0px;
      border-bottom: 1px solid rgb(240, 240, 240);
   }
   
   .footer-m{
      position: fixed;
      bottom:0px;
      width:100%;
      background-color: #fff;
      padding-top:10px;
      height:70px;
   
   }
   
   
   #modal-receta-2{
      
      position:fixed !important;
      height:Calc(133vh) !important;
      border-radius:0px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
      padding:0px; 
      background:white; 
      top:0px !important;
      width: 133vw !important;
      transform: none !important;
      max-width:100%;
      max-height: 100%;
      margin: 0px auto !important; 
      left: 0px !important;
      right: 0px !important;
      opacity:1;
      z-index:90001;
      transition:0s;
   }
   
   .body-m{
      overflow-y: scroll;

      height:Calc(133vh - 50px);
      padding-bottom: 60px;

      
   }
   .left-dv, .right-dv{
      height:100%;
   }
   .main-cont{
      
      
   }
   
   
   
   }
   
   .zoom-enter-active,
   .zoom-leave-active {
     animation-duration: 0.1s;
     animation-fill-mode: both;
     animation-name: zoom;
   }
   
   .zoom-leave-active {
     animation-direction: reverse;
   }
   
   @keyframes zoom {
     from {
       opacity: 0;
       transform:  scale3d(0.3, 0.3, 0.3);
     }
   
     100% {
       opacity: 1;
     }
   }
   </style>
   