<template>
  <div>
    <!-- Download Button -->
    <button class="download-button" @click="downloadZip" :disabled="isDownloading">
      <span v-if="isDownloading">Descargando...</span>
      <span  v-else>Descargar Fotos <b-icon icon="download"></b-icon> </span>
    </button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DownloadFotoOrden',
  props: {
    ordenId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isDownloading: false
    }
  },
  methods: {
    async downloadZip() {
      this.isDownloading = true

      try {
        // Construct the download URL
        const url = `${window.hostname}api/property_manager/download_fotoorden_zip/${this.ordenId}`

        // Make the GET request with Axios
        const response = await axios.get(url, {
          responseType: 'blob', // Important for handling binary data
        })

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/zip' })

        // Create a URL for the Blob
        const downloadUrl = window.URL.createObjectURL(blob)

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = downloadUrl

        // Extract filename from Content-Disposition header or use a default
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'FotosOrden.zip' // Default filename

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/)
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = decodeURIComponent(fileNameMatch[1])
          }
        }

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Error downloading the ZIP file:', error)
        // Optionally, display an error message to the user
        this.$notify({
          group: 'download',
          type: 'error',
          title: 'Download Failed',
          text: 'Failed to download the ZIP file. Please try again.'
        })
      } finally {
        this.isDownloading = false
      }
    }
  }
}
</script>

<style scoped>
.download-button {
  background-color: #007bff; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 16px; /* Button padding */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer on hover */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline for link buttons */
  display: inline-block;
  white-space: nowrap;
}

.download-button:hover {
  background-color: #3589e4; /* Darker green on hover */
}

</style>
