<template>
<div style="text-align:left;">
  <TopNav />
  <div class="column-left">
    <SideNav />
  </div>
  <ModalActualizarVisita ref="modalvisita"  />
  <ModalNuevaVisita ref="modalnuevavisita"  />
  <div class="column-right">
    <div class="main-container">
        <div class="">
    <div >
      <div class="">
<div class="container-fluid">
          <div class="shadow-object" v-if="popup" @mouseover="popup = true;" @mouseleave="popup = false;" style="position:fixed; text-align:left; padding:3px;  z-index:9999; width: 200px;" :style="{top: mouseY+'px', left: mouseX-100+'px'}">
         <div>
           <!--
           <span style="font-weight:700;">Cambiar estado</span>
          <div v-on:click="cambiarEstado(v)" v-for="e, v in estados" style="cursor:pointer;">
        {{e}}
</div>-->
<span style="font-weight:700;">Opciones</span>
<div v-if="admin_datos.superadmin" v-on:click="marcar_pagado()" style="cursor:pointer;">
  Marcar pagado trans.
</div>
<div v-on:click="goto_modificar()" style="cursor:pointer;">
  Modificar Orden
</div>


<div v-on:click="goto_modificar_hora()" style="cursor:pointer;">
  Reasignar Profesional
</div>
<div v-on:click="comentando = true" class="cmt" style="cursor:pointer;">
  Comentar
</div>
<div v-on:click="eliminarOrden()" style="cursor:pointer;">
  Eliminar orden
</div>
<!--
<div v-on:click="goto_resultados()" style="cursor:pointer;">
  Subir Resultados
</div>
-->

</div>
      </div>
    <h2 style="margin-bottom:20px;">Órdenes <b-icon icon="cart2"></b-icon></h2>

<div style="position:relative; padding-top:40px;">
    <div style="position:absolute; right:0px; top:10px;"> Desde <input id="x2" type="date" v-model="dt_dia" > Hasta: <input id="x2" type="date" v-model="dt_dia2" ></div>
    <div style="position:absolute; left:0px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;" v-on:click="descargarCSV()">Descargar CSV <b-icon icon="cloud-download"></b-icon> </div>
      <table v-if="loaded" style="width:100%; margin-top:20px; color:white; margin-bottom:270px;">
        <tr style="background-color:black; ">
          <td style="border-bottom: 1px solid black; border-left: 1px solid black;">id</td>
          <td style="border-bottom: 1px solid black; border-left: 1px solid black;">Profesional</td>
          <td style="border-bottom: 1px solid black; border-left: 1px solid black;">Visitas</td>
          <td style="border-bottom: 1px solid black;">Nombre</td>
          <td style="border-bottom: 1px solid black;">Rut</td>
          <td style="border-bottom: 1px solid black;">Email</td>
          <td style="border-bottom: 1px solid black;">Teléfono</td>
          <td style="border-bottom: 1px solid black;">Monto</td>
          <td style="border-bottom: 1px solid black;">Abono</td>
     <!--      <td style="border-bottom: 1px solid black;">Horario</td> -->
          <td style="border-bottom: 1px solid black;">Estado</td>
          <td style="border-bottom: 1px solid black;">Pagado</td>
          <td style="border-bottom: 1px solid black;">Comuna</td>
          <td style="border-bottom: 1px solid black;">Dirección</td>
          <td style="border-bottom: 1px solid black;">Comentarios</td>
          <td style="border-bottom: 1px solid black;">Historial</td>
          <td style="border-bottom: 1px solid black; border-right: 1px solid black;">Servicios</td>
        </tr>
        <tr  v-on:click="mouseEnter($event, o.id)" :style="{'background-color': o.urgente && o.estado == 1 ? '#FFDC00': 'white', 'color': o.urgente ? 'black': 'black', 'opacity':o.estado == 0 ? '0.3' : '1'}" style="vertical-align:top; cursor:pointer;" v-for="o in ordenes_calculated">
          
          <td style="border-left: 1px solid black; border-bottom: 1px solid black;">{{o.id}} </td>
          <td style="border-left: 1px solid black; border-bottom: 1px solid black;">{{o.profesional.nombre}} {{o.profesional.apellido}}</td>
          <td style="border-bottom: 1px solid black;"> <div  v-on:click="showModalVisita(visita)" class="visita no-menu" v-for="visita in o.visitas">{{ new Date(visita.fecha.a, visita.fecha.m-1, visita.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'numeric', day: 'numeric' }) }} {{ timeConvert(visita.desde) }} - {{ timeConvert(visita.hasta) }} </div>
          <button style="margin-top: 10px; margin-bottom: 10px;" class="no-menu btn-estandar" v-on:click="showModalNuevaVisita(o.id)">Nueva Visita</button>  
          </td>

          <td style="border-bottom: 1px solid black;">{{o.usuario.nombre}} {{o.usuario.apellido}}</td>
          <td style="border-bottom: 1px solid black;">{{o.usuario.rut}}</td>
          <td style="border-bottom: 1px solid black;">{{o.usuario.email}}</td>
          <td style="border-bottom: 1px solid black;">{{o.usuario.telefono}}</td>
          <td style="border-bottom: 1px solid black;">${{Intl.NumberFormat('de-DE').format(o.monto)}}</td>
          <td style="border-bottom: 1px solid black;">${{Intl.NumberFormat('de-DE').format(o.monto_pagado)}}</td>
          <!-- <td style="border-bottom: 1px solid black;">{{timeConvert(o.desde)}}-{{timeConvert(o.hasta)}}</td> -->
          <td style="border-bottom: 1px solid black;">
            <select name="" @change="cambiarEstado2(o.id, o.estado)" style="padding:5px;" class="no-menu form-select form-select-lg mb-3" v-model="o.estado" id="">
              <option v-for="e, z in estados" :value="z" >{{e}}</option>
            </select>
 
          </td>
          <td style="border-bottom: 1px solid black; text-align:center;"><b-icon style="color:green; height:50px; width:50px;" v-if="o.monto_pagado >= o.monto" icon="check"></b-icon> <b-icon v-else style="color:red; height:40px; width:40px;" icon="x-circle"></b-icon></td>

          <td style="border-bottom: 1px solid black;">{{comunas.filter(x=>{return x.id == o.comuna_id})[0].Nombre}}</td>
          <td style="border-bottom: 1px solid black;">{{o.direccion}}</td>
          <td style="border-bottom: 1px solid black;"><div v-for="c in o.comentarios.slice().filter(x=>{return x.tipo == 2}).sort(compareDt)" ><b-icon v-on:click="eliminarComentario(c.id)" class="no-menu" style="color:red" icon="trash" v-if="admin_datos.id == c.autor.id"/> <b>{{new Date(c.fecha.a, c.fecha.m-1, c.fecha.d, c.fecha.h, c.fecha.min).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}} por {{c.autor.nombre}}:</b> {{c.texto}}  </div></td>
          <td style="border-bottom: 1px solid black;"><div v-for="c, i in o.comentarios.slice().filter(x=>{return x.tipo == 1}).sort(compareDt)" v-if="(i < 2 || o.viendoHistorial)"> <b>{{new Date(c.fecha.a, c.fecha.m-1, c.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}}:</b> {{c.texto}} </div><div style="width:100%; text-align:center; padding:10px 0px;"> <button v-if="o.comentarios.slice().filter(x=>{return x.tipo == 1}).length > 2" v-on:click="o.viendoHistorial = !o.viendoHistorial; $forceUpdate();" style="margin: 0 auto; min-width:110px;" class=" btn btn-info no-menu">{{o.viendoHistorial ? 'Esconder historial': 'Mostrar Historial'}}</button></div></td>
          <td style="border-right: 1px solid black; border-bottom: 1px solid black;">
            <div :style="{'color': e.visita_id ? 'green': 'red'}" v-for="e in o.servicios"> -{{e.cantidad}} x {{e.servicio_id != 0 && servicios.find(x=> {return x.id == e.servicio_id}) ? servicios.find(x=> {return x.id == e.servicio_id}).nombre : e.nombre}} </div></td>
            

        </tr>
        <tr style="border-top: 1px solid black;">

        </tr>
    
      </table>
      <div v-if="(ordenes_calculated.length == 0 && loaded)" style="position: absolute; left:50%; top:200px; transform: translateX(-50%);">¡No hay ordenes para la fecha seleccionada! </div>
      <div v-else-if="!loaded" style="position: absolute; left:50%; top:200px; transform: translateX(-50%);">
        <div  style="color:rgba(0,0,0,0.8); display: inline-block; font-size: 14px;">Cargando...</div>
                      <div class="spinner-border spinner-border spinner-border-sm"
                      style="
                        margin-left: 10px;
                        margin-right: 14px;
                        margin-top: 2px;
                        margin-bottom: 2px;
                        color: grey;
                      "></div>
      
      
      </div>

  </div>
</div>



  </div>
  </div>
             </div>
</div>






  </div>
  <div v-if="comentando">
   
   <div style="position:fixed; background-color:black; height:133vh; width:133vw; top:0px; z-index:10000; opacity:0.5;">


   </div>
   <div class="cmt" style="position:fixed; border-radius:10px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:10001;">
       <div class="cmt">
      <h4 class="cmt" style="margin-bottom:20px;"> Comenta la orden {{id_orden}}</h4>
      <b-icon class="cmt" v-on:click="comentando = false;" style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;" icon="x-circle"></b-icon>
       <!--<input type="textarea" name="" v-model="comentario" class="cmt" style="height:400px; width:100%;" id="">
       -->
       <div class="cmt" style="padding:0px 10px 0px 0px;">
       <textarea id="w3review" name="w3review" class="cmt" style="resize: none; width:100%; height:250px;" rows="4"  cols="50" v-model="comentario" />
       

</div>

<div class="cmt" style="position:absolute; right:20px;">  ({{comentario.length}}/500)</div>
<div class="cmt" style="width:100%;">
       <button class="btn btn-dark" style="margin: 10px;" v-on:click="comentar()">Enviar</button>
       </div>
       </div>
   </div>
 </div>

  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import ModalActualizarVisita from '@/components/ModalActualizarVisita.vue';
import ModalNuevaVisita from '@/components/ModalNuevaVisita.vue';
import TopNav from '@/components/TopNav.vue';
import InputColorPicker from 'vue-native-color-picker'

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    ModalActualizarVisita,
    ModalNuevaVisita,
    InputColorPicker,
  },
  data() {
    return {

      loaded:true,
      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      dt_dia:'',
      dt_dia2:'',

      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

      visita_:{},

      ordenes:[],
      ordenes_calculated:[],
      id_orden:0,

      popup: false,
      mouseX:0,
      mouseY:0,

      comentando:false,
      comentario:'',

      resultado:'',
      estados:['Pendiente', 'Agendado',  'Confirmado', 'En proceso', 'Atendido', 'Evaluado'],


    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),

    showModalVisita(visita){

      this.$refs.modalvisita.show(visita);

    },
    showModalNuevaVisita(orden_id){

      this.$refs.modalnuevavisita.show(orden_id);

    },
    eliminarComentario(cid){
        axios.post(window.hostname+'api/admin/eliminar_cometnario', 
        {comentario_id:cid})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
         
        })

    },
    eliminarOrden(cid){
        axios.post(window.hostname+'api/admin/borrar_orden', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
          this.popup = false;
         
        })

    },

    cambiarEstado(e){
        axios.post(window.hostname+'api/admin/cambiarEstado', 
        {orden_id:this.id_orden, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
      cambiarEstado2(o, e){
        axios.post(window.hostname+'api/admin/cambiarEstado', 
        {orden_id:o, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
    comentar(){
        if(this.comentario.length <= 500)
        {
        axios.post(window.hostname+'api/admin/hacer_comentario', 
        {orden_id:this.id_orden, texto:this.comentario})
        .then(response => 
        {
          this.get_ordenes()
          this.comentando = false;
          this.comentario = ''
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })
        }

    },
    goto_resultados(){
      this.$router.push({ name: 'SubirResultado', params: { orden_id: this.id_orden } })
    },
    goto_modificar(){
      this.$router.push({ path: '/ModificarOrden/'+this.id_orden})
    },


    goto_modificar_hora(){
      this.$router.push({ path: '/ModificarHorario/'+this.id_orden})
    },
    marcar_pagado(e){
        axios.post(window.hostname+'api/superadmin/marcar_como_pagado_transferencia', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.$notify({duration:5000, group: 'foos', title: '¡Actualizado!', text: '¡Se ha marcado como pagado!', type: 'success'})

          this.popup = false;
        })

        .catch(e=>{
            this.$notify({duration:5000, group: 'foos', title: '¡Error!', text: e.response.data.message, type: 'error'})   
        })

    },
     compareDt(a, b)
        {
          let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d, a.fecha.h, a.fecha.min, a.fecha.seg)
          let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d, b.fecha.h, b.fecha.min, b.fecha.seg)
          if(da > db){
            return -1
          }
          if (da < db){       
            return 1
          }
          return 0

        },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
      mouseEnter(event, order_id) {

  
          if (!event.target.classList.contains('no-menu')){
            const scaleFactor = 0.75;
            this.popup = true;
            this.id_orden = order_id
            //this.$el.addEventListener('mousemove', this.mouseMove, false);
            this.mouseX = event.clientX / scaleFactor;
            this.mouseY = event.clientY / scaleFactor;
          }
      },
      mouseLeave(event) {
        if (!(event.clientX >= this.mouseX && event.clientY >= this.mouseY))
        {

          this.popup = false;
        }

      },
    close (e){
      /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
      if (!(e.target.classList.contains('dontClose')))
        this.showinFuncionesList = false
      if (!e.target.classList.contains('cmt') && this.comentando)
        this.comentando = false;
    },
    get_ordenes(){
      
      if (this.desde.a != 0 && this.hasta.a != 0)
      {
        this.loaded = false;
        axios.post(window.hostname+'api/admin/get_ordenes_dia', 
        {dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a})
        .then(response => 
        {
          this.ordenes = response.data.ordenes
            function compare(a, b)
            {
              let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d)
              let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d)
              if ((a.urgente && a.estado == 1)  && !(b.urgente && b.estado == 1)){
                return -1                
              }
              if (!(a.urgente && a.estado == 1) && (b.urgente && b.estado == 1)){
                return 1
              }
              if(da > db){
                return 1
              }
              if (da < db){       
                return -1
              }
              return 0

            }
          this.ordenes_calculated = []
          var ins = {}
          var hoy = new Date();
          var dia = new Date();
          for (var i = 0; i < this.ordenes.length; i++){
            ins = this.ordenes[i]
            dia = new Date(ins.fecha.a, ins.fecha.m-1, ins.fecha.d)
            if ((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)) < 2*24)
              ins.urgente = true;
            else
              ins.urgente = false;
            ins.viendoHistorial = false;
            this.ordenes_calculated.push(ins)
            //console.log((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)), dia, hoy.getTime())
          }
          this.ordenes_calculated.sort(compare)
          this.loaded = true;
        })
        

        .catch(e=>{
          console.log(e)
          this.loaded = true;      
        })
    }
    

    },

  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'servicios']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    hoy.setDate(hoy.getDate());
    this.dt_dia2 = hoy.toISOString().split('T')[0]

    this.$root.$on('OrdenesInit', data => {
      this.get_ordenes()
    });

  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){

        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },

  
  }

}
</script>


<style scoped>
.btn-estandar{
  margin: 0 auto;
  background-color: rgb(20, 134, 231);
  color:white;
  border-radius: 4px;
  font-weight: 700;
  transition: 0.1s;
  outline: none;
  border: none;
  font-size: 14px;
}
.btn-estandar:hover{
  background-color: rgb(39, 170, 226);
  color:white;
}
.visita{
  background-color: rgba(46, 204, 64, 0.1); border-radius: 4px; color:#2ECC40; display: inline-block; padding: 4px 14px; margin-top: 5px;
}
td{
  text-align: left;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.column-left{

  height:133vh;
  float: left;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}

td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

