<template>
<transition name="zoom">


<div v-if="showing" id="modal-receta" >
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
  <div class="main">
  <h2  class="titulo1">¡Sube tu imagen de perfil!</h2>
  	<cropper
    :class="{'no-disp':croppedImg == null}"
    class="cropper"
		:src="img"
    :stencil-props="{
		aspectRatio: 1
	  }"
		@change="change"
	/>
  <div class="subtitulo2"> </div>
  <div>
      <input v-on:change="change_file()" :id="'file'" :ref="'file'"  type="file" accept="image/*, application/pdf" style="visibility:hidden; width:0px;" />

      <label style="position:absolute; top: 50%; rigth:50%; transform:translate(-50%, -50%);" v-show="croppedImg == null" class="btn btn-std"  :for="'file'">Subir Imagen <b-icon icon="camera"></b-icon></label>
  <button v-show="croppedImg != null" v-on:click="uploadImg()" style="margin-top:4px;" class="btn btn-std">Utilizar Imagen <b-icon icon="upload"></b-icon></button>
  </div>
  <!--   
  <div class="texto">En estos momentos, el sitio de Jobbing se encuentra en construcción &#128679;. Puedes contactarnos enviandonos un correo a <a href="mailto:contacto@jobbing.cl" class="greeny">contacto@jobbing.cl</a>.</div>
  -->
  </div>
</div>
</transition>
</template>

<script>
import axios from 'axios';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
  name: 'ModalMensaje',
  components: {
    Cropper,
  },
  props: {
    mensaje: String,
    titulo: String,
  },
  data(){
    return {
      showing:false,
      img:'',
      croppedImg:null,

    }
  },
  methods:{
    show(){
      this.showing = true;
    },
    hide(){
      this.img = '';
      this.croppedImg = null;
      this.showing = false;
    },
    uploadImg(){
      if (this.croppedImg != null){
        var link = document.createElement("a");
        link.download = "image.png";
        this.croppedImg.toBlob(blob=>{
          link.href = URL.createObjectURL(blob);
          var data  = new FormData();
          data.append('file', blob)
          axios.post(window.hostname+'api/profesional/upload_profile_img', data)
          .then(r=>{
            console.log(r)
            this.$emit('listo')
          })
          .catch(e=>{
            console.log(e)
          })
          console.log(blob);
        })
      }
      
    },
    change({ coordinates, canvas }) {
			console.log(coordinates, canvas);
      this.croppedImg = canvas;
      /*
      var link = document.createElement("a");
      link.download = "image.png";
      var x = canvas.toBlob(function(blob){
    link.href = URL.createObjectURL(blob);
    console.log(blob);
    //this.img  = link.href // this line should be here
  },'image/png');
    console.log(x, "ASDASD")
*/
		},
    change_file(){
      console.log(this.$refs['file'].files[0], )
    if (['jpg', 'png', 'jpeg', 'pdf'].includes(this.$refs['file'].files[0].name.split('.').pop()))
      this.img = URL.createObjectURL(this.$refs['file'].files[0])
    else
      this.error_archivo = true;
    },

  },
  mounted(){
  
  },
  computed:{

  },
  watch:{
  
  },
}
</script>


<style scoped>
.no-disp{
  opacity: 0;
  visibility: hidden;

}
.cropper {
	height: 300px;
	width: 400px;
	background: #DDD;
}

.titulo1{
  margin-bottom:30px;
  font-size:25px;
}
.texto{
  text-align: left;
}
.main{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta{
   transition: 1s;
   position:fixed;
   height:Calc(133vh - 240px);
   max-height: 500px;
   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:700px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta h4{
  text-align: center;
  color:rgb(20, 134, 231) !important;
  margin-bottom:25px;
  margin-top:15px;
}
#modal-receta h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta .icon{
   height:40px; width:40px; color:white;
}
#modal-receta .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color:#FF8C00;
   border-radius:50px;
   z-index: 100001;
}
#modal-receta .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta .input-wrapper{
   padding:0px 5px;
}
#modal-receta .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(133vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta .receta-label-mobile{
   display: none;
}
#modal-receta .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta .comuna{
   position:relative;
}
#modal-receta .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(133vw - 80px);
   color:white !important;
}
#modal-receta .receta-label-mobile{
   display: inline-block;
}
#modal-receta .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta .receta-label-desk{
   display: none;
}
#modal-receta .draggable-container{
   border:none;
   background: none;
}
#modal-receta .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(133vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta{
   
   position:fixed !important;
   height:Calc(133vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 133vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
