<template>
<div style="text-align:left;">
  <TopNav />
  <SubirImagen @listo="initialize_" ref="subirimagen" />
  <div class="column-left">
    <SideNav />
  </div>
  <div class="column-right">
    <div class="main-container">
      <div class="">
        
        <div v-if="loaded">
            
            <div style="display:inline-block; text-align:center;">
            <img style="max-height:300px; display:block;" :src="info.link" alt="">
            <button style="margin-top:5px;" v-on:click="$refs.subirimagen.show()" class="btn btn-std">Cambiar Imagen</button>
            </div>
                  <div style="margin-top:40px;" class="input-cont">
                  <input v-model="nombre" @focus="focused_nombre = true;" @blur="focused_nombre = false;" type="text">
                  <div :class="{'subido':focused_nombre || nombre != ''}" class="input-label">Nombre</div>
                  </div>

                  <div style="" class="input-cont">
                  <input v-model="apellido" @focus="focused_apellido = true;" @blur="focused_apellido = false;" type="text">
                  <div :class="{'subido':focused_apellido || apellido != ''}" class="input-label">Apellido</div>
                  </div>

                  <div style="" class="input-cont">
                  <input v-model="email" @focus="focused_email = true;" @blur="focused_email = false;" type="text">
                  <div :class="{'subido':focused_email || email != ''}" class="input-label">Correo electrónico</div>
                  </div>


                  <div style="" class="input-cont">
                  <input v-model="telefono" @focus="focused_telefono = true;" @blur="focused_telefono = false;" type="text">
                  <div :class="{'subido':focused_telefono || telefono != ''}" class="input-label">Teléfono</div>
                  </div>
                  <div>
                    <button @click="guardar_datos()" class="btn btn-std"> Guardar datos</button>
                  </div>



        
        </div>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import InputColorPicker from 'vue-native-color-picker'
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import SubirImagen from '@/components/SubirImagen.vue';
export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    TopNav,
    SubirImagen,
    InputColorPicker,
  },
  data() {
    return {
    loaded:false,

    nombre:'',
    apellido:'',
    email:'',
    telefono:'',
    direccion:'',
    tel_prefijo:'+56',

    focused_nombre:false,
    focused_apellido:false,
    focused_email:false,
    focused_telefono:false,
    focused_direccion:false,
    seleccionando_telefono:false,

    info:{},

    banderas:[
        {arch:'cl.svg', num:'+56', nom:'Chile'},
        {arch:'ar.svg', num:'+54', nom:'Argentina'},
        {arch:'br.svg', num:'+55', nom:'Brasil'},
        {arch:'us.svg', num:'+1', nom:'Estados Unidos'},
        {arch:'co.svg', num:'+57', nom:'Colombia'},        
        {arch:'mx.svg', num:'+52', nom:'Mexico'},
        {arch:'pe.svg', num:'+51', nom:'Peru'},
        {arch:'uy.svg', num:'+598', nom:'Uruguay'},
        {arch:'bo.svg', num:'+591', nom:'Bolivia'},
    ],


     window: {
        width: 0,
        height: 0
      },
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);

  },
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  
    this.initialize_(this.$route.params.id);

  },

  methods:{
    ...mapActions(['initialize', 'getpro']),

    getFlagUrl(pic) {
    return require('../assets/banderas/'+pic)
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
   
    
      initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        this.nombre = this.admin_datos.nombre
        this.apellido = this.admin_datos.apellido
        this.email = this.admin_datos.email
        this.telefono = this.admin_datos.telefono
        axios.post(window.hostname+'api/profesional/init_modificar_perfil', 
        {id: pid})
        .then(response => 
        {
            this.loaded = true;
             this.info  = response.data
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })
      }
    },
      guardar_datos(){   
        axios.post(window.hostname+'api/profesional/modificar_datos', 
        {nombre: this.nombre,
        apellido: this.apellido,
        email: this.email,
        telefono: this.telefono})
        .then(response => 
        {
          this.loaded = true;
          this.$notify({
            group: 'foos',
            title: '¡Éxito!',
            type: 'success',
            text: '¡Se han actualizado los datos!',
          });
          this.getpro();
        })

        .catch(e=>{
            this.loaded = true;

        })
      
    },
  },
  computed:{
    ...mapGetters(['admin_datos']),

    viendoSemana: function(){
      var o;
      var f = [new Date(this.viendoAño, this.viendoMes-1, this.desdeD.getDate())];
      var ret = [this.desdeD.getDate()]
      for (var i = 1; i < 7; i++)
      {
        o = new Date(f[i-1].getFullYear(), f[i-1].getMonth(), f[i-1].getDate())
        o.setDate(o.getDate() + 1)
        f.push(o)
        ret.push(o.getDate())
      }

      return ret;
     
    },
    horas: function(){
      let h = []
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {

          h.push(this.profesional.horarios[i].ordenes[j])
      
        }

      }
      return h;

    },
    horaMin: function(){
      var min = 360;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde < min)
          {
            min = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return min
    },

    horaMax: function(){
      var max = 1080;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde > max)
          {
            max = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return max
    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    profesional(){
      this.editando_categorias = []
      for(var i = 0; i < this.categorias.length; i++){
        this.editando_categorias.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, puede:this.profesional.categorias.some(x=>{return x == this.categorias[i].id})})
      }
    }
  }

}
</script>
<style  scoped>
.input-cont{
  position:relative;
  text-align:left;
  max-width:400px;
  margin-bottom:40px;
}
.input-cont input{
  padding: 4px;
  border:none;
  border-bottom:1px solid rgba(0,0,0,0.4);
  width:100%;
  outline:none;
}
.input-label{
  position:absolute;
  top:3px;
  left:6px;
  pointer-events: none;
  color:rgba(0,0,0,0.6);
  transition: 0.2s;
}
.subido{
  top:-12px;
  left:0px;
  font-size: 12px;
  color:#FF8C00;
}
.subido2{
  top:-16px;
  left:-84px;
  font-size: 12px;
  color:#FF8C00;
}

.d-comunas{
  margin-top:15px;
}

 .vig{
   display: inline-block;
   border: 2px solid #FF8C00;
   padding: 4px 10px;
   margin-right:5px;
   border-radius: 5px;
   color:#FF8C00;
   cursor: pointer;
 }
 .vig-select{
   background: #FF8C00;
   color:white;
 }

 .numerito{
   background: rgba(0,0,0,0.8); color:white; font-size:13px; font-weight:700; border-radius:100px; padding: 1px 6px;
 }
 .column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  padding-left:260px;
  width:100%;

}
.column-left{

  height:133vh;
  float: left;

}
.main-container{
  background-color: white;

  margin-top:10px;
  padding:20px 20px 10px 20px;
}

td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>