<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <div class="main-container">       
        <div v-if="loaded" class="articulo-principal">
          <h2 class="section-title">Profesionales</h2>
          <div class="table-responsive">
            <table class="table table-hover table-nowrap">
              <thead class="bg-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Calificación</th>
                  <th scope="col">Contacto</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="p.profesional.id != 10"
                  v-for="p in profesionales"
                  :key="p.profesional.id"
                  class="clickable-row"
                >
                  <td class="align-middle">
                    <img
                      :src="p.profesional.foto || defaultAvatar"
                      alt="Imagen profesional"
                      class="avatar rounded-circle me-2"
                    />
                    {{ p.profesional.nombre }} {{ p.profesional.apellido }}
                  </td>
                  <td class="align-middle">
                    <div v-if="p.clasificacion[0] === 0">
                      <em>Sin calificaciones</em>
                    </div>
                    <div v-else>
                      <span v-for="s in fullStars(p)" class="text-warning">
                        <b-icon icon="star-fill"></b-icon>
                      </span>
                      <span v-if="hasHalfStar(p)" class="text-warning">
                        <b-icon icon="star-half"></b-icon>
                      </span>
                      <span v-for="s in emptyStars(p)" class="text-warning">
                        <b-icon icon="star"></b-icon>
                      </span>
                      <span>({{ averageRating(p) }})</span>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div v-if="p.showContactInfo">
                      <span>
                        <b-icon icon="whatsapp"></b-icon> {{ p.profesional.telefono }}
                      </span>
                      <br />
                      <span>
                        <b-icon icon="envelope"></b-icon> {{ p.profesional.email }}
                      </span>
                    </div>
                  </td>
                </tr>  
              </tbody>
            </table>
          </div>    
        </div>
        <div v-else class="articulo-principal">
          Cargando...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'ProfesionalesEmpresa',
  components: {
    SideNav,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      profesionales: [],
      defaultAvatar: 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png',
    };
  },
  created() {
    this.initialize_();
  },
  methods: {
    ...mapActions(['initialize']),
    initialize_() {
      this.loaded = false;
      axios
        .get(`${window.hostname}api/property_manager/get_profesionales`)
        .then(response => {
          this.loaded = true;
          this.profesionales = response.data.profesionales.map(p => ({
            ...p,
            showContactInfo: true,
          }));
        })
        .catch(e => {
          this.loaded = true;
          console.error('Error al cargar...', e);
        });
    },

    averageRating(p) {
      return (p.clasificacion[1] / p.clasificacion[0]).toFixed(2);
    },
    fullStars(p) {
      return Math.floor(p.clasificacion[1] / p.clasificacion[0]);
    },
    hasHalfStar(p) {
      return (
        p.clasificacion[1] / p.clasificacion[0] -
          Math.floor(p.clasificacion[1] / p.clasificacion[0]) >=
        0.5
      );
    },
    emptyStars(p) {
      return 5 - Math.ceil(p.clasificacion[1] / p.clasificacion[0]);
    },
  },
  computed: {
    ...mapGetters(['admin_datos']),
  },
  mounted() {
    this.$root.$on('update-profesionales', () => {
      this.initialize_();
    });
  },
};
</script>

<style scoped>
.avatar {
  height: 60px;
  width: 60px;
  object-fit: cover;
}
.text-heading {
  font-weight: 600;
}
.table-responsive {
  margin-bottom: 0;
}
.table thead th {
  background-color: #fff;
  border-top:none;
  border-bottom: none;
}
.table-hover tbody tr:hover {

}
.column-right {
  background-color: #f2f7fb;
  min-height: 133vh;
  padding-top: 80px;
  padding-left: 260px;
  width: 100%;
}
.main-container {
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
}
.section-title {
  margin-bottom: 20px;
}
.clickable-row {
}
.clickable-row:hover {

}
@media (max-width: 800px) {
  .column-right {
    padding-left: 0;
  }
}
h2{
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}
</style>
